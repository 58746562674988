.journey-dndflow {
  display: flex;
	width: 100%;
	position: relative;
}

.journey-toolbar {
  width: 200px;
  padding: 10px;
  background-color: #2d2d2d;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.journey-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.journey-section h3 {
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #4d4d4d;
  font-size: 14px;
  color: #bfbfbf;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.journey-add-button {
  padding: 8px;
  color: white;
  background-color: #5d5d5d;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.1s ease;
}

.journey-add-button:hover {
  background-color: #7d7d7d;
}

.journey-flow {
  flex-grow: 1;
  background-color: #f0f0f0;
}

.journey-file-node,
.journey-default-node,
.journey-action-node,
.journey-flow-node {
  position: relative;
  padding: 10px 22px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
}

.journey-flow-node pre {
	white-space: pre-wrap;
	word-wrap: break-word;
	font-size: 8px;
}

.journey-file-node {
  background-color: #616161;
	color: white;
}

.journey-default-node {
  background-color: #2c4f50;
	color: white;
}

.journey-action-node {
  background-color: #2c3e50;
  color: white;
}

.journey-flow-node {
	background-color: #227e4c;
	color: white;
}

.journey-delete-button {
	position: absolute;
	top: 3px;
	right: 3px;
	background: #333;
	color: white;
	border: none;
	border-radius: 5px;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 8px;
	font-weight: bold;
	transition: background 0.1s ease;
}

.journey-delete-button:hover {
	background: #555;
}


.journey-action-node .journey-date-action-config {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.journey-date-action-input,
.journey-occurrence-select {
  margin: 0;
  width: 100%;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 10px;
}


.journey-dndflow input[type=number]::-webkit-inner-spin-button,
.journey-dndflow input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.journey-dndflow input[type=number] {
  -moz-appearance: textfield;
}

.journey-sidebar {
  width: 300px;
  height: 100%;
  background-color: #3a3a3a;
  color: white;
  padding: 20px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  z-index: 100;
  overflow-y: auto;
}

.journey-sidebar-close {
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  float: right;
  margin-bottom: 15px;
}

.journey-sidebar h3 {
  margin-top: 0;
  font-size: 20px;
  color: #e0e0e0;
  border-bottom: 1px solid #555;
  padding-bottom: 10px;
}

.journey-sidebar label {
  font-size: 14px;
  margin-top: 15px;
  display: block;
}

.journey-sidebar input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #2d2d2d;
  color: white;
}

.journey-sidebar input:focus {
  outline: none;
  border-color: #ffcc00;
  background-color: #444;
}

.journey-sidebar select {
  width: 100%;
  padding: 8px;
	margin-top: 5px;
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background-color: #2d2d2d;
  transition: border-color 0.3s;
}

.journey-sidebar select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.journey-sidebar textarea {
	width: 100%;
	padding: 10px;
	margin-top: 5px;
	border: 1px solid #555;
	border-radius: 5px;
	background-color: #2d2d2d;
	color: white;
}
